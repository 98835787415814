import React from "react";
import "./LoaderStyles.scss";

const Loader = () => {
  return (
    <article className="loader">
        <h1>C</h1>
    </article>
  );
};

export default Loader;
